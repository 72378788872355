// React
import * as React from "react";
import { useRef, useLayoutEffect } from "react";

// CSS
import "normalize.css";
import styled from "styled-components";

// GSAP
import gsap from "gsap";

// Meta Information
import SEO from "../components/reusable/Seo";
import { Helmet } from "react-helmet";

// Components: HTML Structure
import Layout from "../components/structure/Layout";
import MainContent from "../components/structure/MainContent";
import Branding from "../components/structure/Branding";
import Header from "../components/structure/Header";

// Gatsby
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

// React3Fiber
import VisionLineMesh from "../components/reusable/VisionLineMesh";
import { Canvas } from "@react-three/fiber";

import { technoOrange } from "../components/styles/global.module.css";

const Container = styled.div`
  display: grid;
  grid-template-areas: "vision";
`;

const Synapses = styled.div`
  width: 100%;
  height: 100%;
  grid-area: 2/3/3/5;
`;

const startOverLink = {
  gridArea: "3/1/4/3",
  alignSelf: "start",
  textDecoration: "none",
};

function NotFoundPage() {
  const tlBranding = useRef();
  tlBranding.current = gsap.timeline();
  const breakpoints = useBreakpoint();

  function getStandAloneTimelines(tl, name) {
    switch (name) {
      case "tl-branding":
        tlBranding.current.add(tl, name + `+=1`);
        break;
    }
  }

  useLayoutEffect(() => {
    return function cleanupListener() {
      try {
        tlBranding.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <React.Fragment>
      <SEO title="404 Page" />
      <Helmet>
        <title>404 Page</title>
      </Helmet>
      <Container>
        <Layout>
          <MainContent>
            <Synapses>
              {breakpoints.sm ? (
                <figure>
                  <img src="/synapses.svg" alt="Synapses" />
                </figure>
              ) : (
                <Canvas
                  camera={{
                    position: [0, 0, 1150],
                    fov: 45,
                    near: 1,
                    far: 4000,
                  }}
                >
                  <ambientLight intensity={0.1} />
                  <directionalLight position={[0, 0, 5]} />
                  <VisionLineMesh />
                </Canvas>
              )}
            </Synapses>
            <Branding getStandAloneTimelines={getStandAloneTimelines} />
            <Header title="404 - Page" highlighted="Not Found">
              <Link to="/" style={startOverLink} className={technoOrange}>
                How about we start over?
              </Link>
            </Header>
          </MainContent>
        </Layout>
      </Container>
    </React.Fragment>
  );
}

export default NotFoundPage;
